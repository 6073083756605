/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';

import BootstrapTable from 'react-bootstrap-table-next';

import {
  Modal,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import {
  splitPeopleElements,
} from '../../utils/helper';

const RequirementsModal = (props) => {
  const {
    isShowing,
    hide,
    selected,
  } = props;

  if (!selected) {
    return null;
  }

  const columns = [
    {
      dataField: "requirement",
      text: "Skill",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Required",
      sort: true,
    },
    {
      dataField: "people",
      text: "Shifted",
      sort: true,
      formatter: (cellContent) => splitPeopleElements(cellContent),
    }
  ];

  return (
    ReactDOM.createPortal(
      <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
        <Modal.Header>
          <Modal.Title className="h6">Requirements</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable
            bootstrap4
            keyField="requirement"
            data={selected.requirements2}
            columns={columns}
            wrapperClasses="table-responsive"
            headerClasses="thead-light"
          // noDataIndication={() => <NoDataIndication />}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>,
      document.body,
    )
  );
};

export default RequirementsModal;
