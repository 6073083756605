/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  getCompany,
  getProcessing,
  getTeam,
} from '../../api/get';

import { useAuth } from '../../contexts/AuthContext';

import WeekButtons from '../WeekButtons';
import Content from '../Content';
import TitleBar from '../TitleBar';
import BranchSelector from '../BranchSelector';
import Inspection from './Inspection';
import Schedule from './Schedule';

import { getToday, getIsoWeek, getIsoYear } from '../../utils/moment-utils';

import Loader from '../Loader';

const SchedulePage = () => {
  const { currentUserToken } = useAuth();

  const [isInspecting, setIsInspecting] = useState();
  const [selectedDate, setSelectedDate] = useState(getToday());
  const [branch, setBranch] = useState();
  const [isShiftView, setIsShiftView] = useState(true);

  const { data: company, error: errorCompany } = getCompany(currentUserToken);
  const { data: team, error: errorTeam } = getTeam(currentUserToken);
  const { data: processing, error: errorProcessing, mutate: mutateProcessing } = getProcessing(currentUserToken, getIsoWeek(selectedDate), getIsoYear(selectedDate));

  useEffect(() => {
    if (company && !errorCompany && company['branches'].length) {
      setBranch(company['branches'][0]);
      console.log('branch set again');
    }
  }, [company]);

  useEffect(() => {
    if (processing && !errorProcessing && processing['exists']) {
      setIsInspecting(!processing['published']);
      return;
    }
    setIsInspecting(true);
  }, [processing]);


  if (!company || errorCompany || !team || errorTeam) {
    return <Loader />;
  }

  const reload = () => {
    mutateProcessing();
  }

  return (
    <Content>
      <TitleBar title="Schedule" status={isInspecting === undefined || isInspecting === true ? 'inspecting' : 'published'}>
        {isShiftView &&
          <BranchSelector
            branch={branch}
            setBranch={setBranch}
            company={company}
          />
        }
        <WeekButtons
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate} />
      </TitleBar>

      <hr />

      {isInspecting === undefined || isInspecting === true ?
        <Inspection
          team={team}
          selectedDate={selectedDate}
          branch={branch}
          reload={reload}
          isShiftView={isShiftView}
          setIsShiftView={setIsShiftView}
          processing={processing}
        />
        :
        <Schedule
          team={team}
          selectedDate={selectedDate}
          branch={branch}
          reload={reload}
          isShiftView={isShiftView}
          setIsShiftView={setIsShiftView}
          processing={processing}
        />
      }

    </Content>
  );
};

export default SchedulePage;
