import axios from 'axios';
import apiURL from '../api';
// import useAxiosPost from '../hooks/useAxiosPost';

export const addBranch = (currentUserToken, branch) => {
  const url = apiURL('company/addBranch');
  const data = {
    branch,
  };

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const addPosition = (currentUserToken, position) => {
  if (!currentUserToken) {
    return null;
  }

  const res = axios({
    method: 'post',
    url: apiURL('company/addPosition'),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    data: {
      position,
    },
  });
  return res;
};

export const addSkill = (currentUserToken, skill) => {
  if (!currentUserToken) {
    return null;
  }

  const res = axios({
    method: 'post',
    url: apiURL('company/addSkill'),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    data: {
      skill,
    },
  });
  return res;
};

export const deleteBranch = (currentUserToken, branch) => {
  const url = apiURL('company/deleteBranch');
  const data = {
    branch,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deletePosition = (currentUserToken, position) => {
  console.log(position);
  const url = apiURL('company/deletePosition');
  const data = {
    position,
  };

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deleteSkill = (currentUserToken, skill) => {
  const url = apiURL('company/deleteSkill');
  const data = {
    skill,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const addTeam = (
  currentUserToken,
  name,
  email,
  branch,
  position,
  skill,
  salary,
  contractualHours,
) => {
  const url = apiURL('team/add');
  const data = {
    name,
    email,
    branch,
    position,
    skill,
    salary,
    contractualHours,
  };

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deleteTeam = (currentUserToken, docId) => {
  const url = apiURL('team/delete');
  const data = {
    memberDocID: docId,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const addShift = (
  currentUserToken,
  name,
  position,
  day,
  start,
  end,
  endsNextDay,
  break_,
  capacity,
  fixedPeople,
  hardRequirements,
  softRequirements,
  week,
  year,
  branch,
) => {
  const url = apiURL('shifts/add');
  const data = {
    name,
    position,
    day,
    start,
    end,
    endsNextDay,
    break: break_,
    capacity,
    fixedPeople,
    hardRequirements,
    softRequirements,
    week,
    year,
    branch,
  };

  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deleteShift = (currentUserToken, shiftID) => {
  const url = apiURL('shifts/delete');
  const data = {
    shiftID,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const clearShifts = (currentUserToken, branch, week, year) => {
  const url = apiURL('shifts/deleteAll');
  const data = {
    branch,
    week,
    year,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const saveTemplate = (currentUserToken, templateName, branch, week, year) => {
  const url = apiURL('shifts/saveTemplate');
  const data = {
    templateName,
    branch,
    week,
    year,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deleteTemplate = (currentUserToken, templateName) => {
  const url = apiURL('shifts/deleteTemplate');
  const data = {
    templateName,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const loadTemplate = (currentUserToken, templateName, branch, week, year) => {
  const url = apiURL('shifts/loadTemplate');
  const data = {
    templateName,
    branch,
    week,
    year,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const publish = (currentUserToken, processingID) => {
  const url = apiURL('inspection/publish');
  const data = {
    processingID,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const unpublish = (currentUserToken, processingID) => {
  const url = apiURL('schedule/unpublish');
  const data = {
    processingID,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const generate = (
  currentUserToken,
  week,
  year,
  goal,
  minHours,
  oneShiftPerDay,
  prioritizeLongerShifts,
  maxWaiting,
) => {
  const url = apiURL('availabilities/generate');
  const data = {
    week,
    year,
    goal,
    minimumHoursBetween: minHours,
    onePerDayShift: oneShiftPerDay,
    prioritizeLongerShifts,
    maxTime: maxWaiting,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const sendReminder = (currentUserToken, week, year) => {
  const url = apiURL('availabilities/sendReminder');
  const data = {
    week,
    year,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const deletePerson = (currentUserToken, docID, email) => {
  const url = apiURL('schedule/delete');
  const data = {
    docID,
    email,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};

export const addPerson = (currentUserToken, emails, docID) => {
  const url = apiURL('schedule/add');
  const data = {
    emails,
    docID,
  };
  return axios({
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUserToken.token}`,
    },
    url,
    data,
  });
};
