import React from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { getScheduleStatus } from '../../api/get';

import Content from '../Content';
import TitleBar from '../TitleBar';

import { getToday, getIsoWeek, getIsoYear } from '../../utils/moment-utils';
import { useAuth } from '../../contexts/AuthContext';
import ScheduleStatus from './ScheduleStatus';

const DashboardCompanyPage = () => {
  const { currentUserToken } = useAuth();
  const selectedDate = getToday();

  const { data: scheduleStatus, error: errorScheduleStatus } = getScheduleStatus(
    currentUserToken,
    getIsoWeek(selectedDate),
    getIsoYear(selectedDate),
  );

  return (
    <Content>
      <TitleBar title="My Overview" />

      <hr />

      <Row>
        <Col lg={6}>
          <ScheduleStatus
            scheduleStatus={scheduleStatus}
            error={errorScheduleStatus}
          />
        </Col>
      </Row>
    </Content>
  );
};

export default DashboardCompanyPage;
