/* eslint-disable */
import React, { useState } from 'react';

import {
  Button,
  Modal,
  Form,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import { useAuth } from '../../contexts/AuthContext';

import {
  addTeam,
} from '../../api/post';


// Libraries
// import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select';
// import axios from 'axios';

const AddTeamModal = (props) => {
  const {
    isShowing,
    hide,
    reload,
    company,
  } = props;

  if (!company) {
    return null;
  }

  const positions = company.positions.map((x) => ({
    label: x,
    value: x,
  }));

  const branches = company.branches.map((x) => ({
    label: x,
    value: x,
  }));

  const skills = company.skills.map((x) => ({
    label: x,
    value: x,
  }));

  const { currentUserToken } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [branch, setBranch] = useState([]);
  const [position, setPosition] = useState([]);
  const [skill, setSkill] = useState([]);
  const [salary, setSalary] = useState('');
  const [contractualHours, setContractualHours] = useState('');

  const handleAdd = (e) => {
    e.preventDefault();

    addTeam(
      currentUserToken,
      name,
      email,
      branch.map((x) => x.value),
      position.map((x) => x.value),
      skill.map((x) => x.value),
      salary,
      parseInt(contractualHours) ? parseInt(contractualHours) : null,
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  return (
    <Modal as={Modal.Dialog} size="xl" centered show={isShowing} onHide={hide}>
      <Form onSubmit={handleAdd}>
        <Modal.Header>
          <Modal.Title className="h6">Add team member</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="John Doe"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="john@example.com"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Branch</Form.Label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="branches"
                  options={branches}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={branch}
                  onChange={(event) => setBranch(event)}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Position</Form.Label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="positions"
                  options={positions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={position}
                  onChange={(event) => setPosition(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Skill</Form.Label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="colors"
                  options={skills}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={skill}
                  onChange={(event) => setSkill(event)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Salary (Hourly)</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="#"
                  value={salary}
                  onChange={(event) => setSalary(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Contract Hours*(weekly-optional)</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="100"
                  step="1"
                  placeholder="#"
                  value={contractualHours}
                  onChange={(event) => setContractualHours(event.target.value)} 
                />
              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Add
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddTeamModal;
