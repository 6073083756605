import React from 'react';
import {
  Card,
  Button,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import SkillsTable from './SkillsTable';
import AddSkillModal from './AddSkillModal';
import { LoadingCard } from '../Loader';

import useModal from '../../hooks/useModal';

const Skills = (props) => {
  const { company, error, reload } = props;
  const { isShowing, toggle } = useModal();

  if (!company || error) {
    return <LoadingCard title="SKILLS" />;
  }

  return (
    <>
      <Card border="grey" className="border-3 shadow-sm mb-4">
        <Card.Header className="d-flex justify-content-between">
          <b>SKILLS</b>
          <Button variant="primary" size="sm" onClick={toggle}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <SkillsTable
            company={company}
            reload={reload}
          />
        </Card.Body>
      </Card>
      <AddSkillModal
        isShowing={isShowing}
        hide={toggle}
        reload={reload}
      />
    </>
  );
};

export default Skills;
