/* eslint-disable */
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

import RequirementsModal from './RequirementsModal';
import PoepleModal from './PeopleModal';
import AddPeopleModal from './AddPeopleModal';

import useModal from '../../hooks/useModal';

import {
  splitArrayElements,
  parseTime,
  renderNoData,
} from '../../utils/helper';

const ScheduleTable = (props) => {
  const { schedule, team, reloadSchedule } = props;

  const [selected, setSelected] = useState();
  const { isShowing: isShowingRequirementsModal, toggle: toggleRequirementsModal } = useModal();
  const { isShowing: isShowingPeopleModal, toggle: togglePeopleModal } = useModal();
  const { isShowing: isShowingAddPeopleModal, toggle: toggleAddPeopleModal } = useModal();

  const openAddPeopleModal = (row) => {
    console.log(row);
    setSelected(row);
    toggleAddPeopleModal();
  };

  const renderButtons = (row) => (
    <Button variant="warning" className="m-1" size="sm" onClick={() => openAddPeopleModal(row)}>
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );

  const openPeopleModal = (row) => {
    console.log(row);
    setSelected(row);
    togglePeopleModal();
  };

  const renderPeople = (cellContent, row) => {
    const capacity = `${cellContent.length}/${row.capacity}`;
    if (cellContent.length < row.capacity) {
      return (
        <Button variant="danger" className="m-1" size="sm" onClick={() => openPeopleModal(row)}>
          {capacity}
        </Button>
      );
    }
    if (cellContent.length === row.capacity) {
      return (
        <Button variant="success" className="m-1" size="sm" onClick={() => openPeopleModal(row)}>
          {capacity}
        </Button>
      );
    }
    return (
      <Button variant="warning" className="m-1" size="sm" onClick={() => openPeopleModal(row)}>
        {capacity}
      </Button>
    );
  };

  const openRequirementsModal = (row) => {
    console.log(row);
    setSelected(row);
    toggleRequirementsModal();
  };

  const renderRequirements = (_, row) => {
    if (row.metReq) {
      return (
        <Button variant="success" className="m-1" size="sm" onClick={() => openRequirementsModal(row)}>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      );
    }
    return (
      <Button variant="danger" className="m-1" size="sm" onClick={() => openRequirementsModal(row)}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    );
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <b>Break: </b>
          {row.break}
        </span>
        <br />
        <span>
          <b>Hard requirements: </b>
          {splitArrayElements(row.hardRequirements)}
        </span>
        <br />
        <span>
          <b>Soft requirements: </b>
          {splitArrayElements(row.requirements)}
        </span>
      </div>
    ),
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'position',
      text: 'Position',
      sort: true,
    },
    {
      dataField: 'time',
      text: 'Time',
      formatter: (_, row) => parseTime(row.start, row.end, row.endsNextDay),
    },
    {
      dataField: 'requirements',
      text: 'Requirements (soft)',
      headerAlign: 'center',
      align: 'center',
      formatter: renderRequirements,
    },
    {
      dataField: 'people',
      text: 'People',
      sort: true,
      formatter: renderPeople,
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      formatter: (_, row) => renderButtons(row),
    },
  ];

  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 1)}
        caption="Monday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 2)}
        caption="Tuesday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 3)}
        caption="Wednesday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 4)}
        caption="Thursday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 5)}
        caption="Friday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 6)}
        caption="Saturday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={schedule.filter((x) => x.day === 7)}
        caption="Sunday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
        noDataIndication={renderNoData}
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <RequirementsModal
        isShowing={isShowingRequirementsModal}
        hide={toggleRequirementsModal}
        selected={selected}
      />
      <PoepleModal
        isShowing={isShowingPeopleModal}
        hide={togglePeopleModal}
        selected={selected}
        reloadSchedule={reloadSchedule}
      />
      <AddPeopleModal
        isShowing={isShowingAddPeopleModal}
        hide={toggleAddPeopleModal}
        team={team}
        selected={selected}
        reloadSchedule={reloadSchedule}
      />
    </>
  );
};

export default ScheduleTable;
