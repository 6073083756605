import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Badge } from '@themesberg/react-bootstrap';

const ScheduleStatusTable = (props) => {
  const { scheduleStatus } = props;

  const renderStatus = (cellContent) => {
    switch (cellContent) {
      case 'published':
        return <Badge bg="success" className="badge-md me-1">published</Badge>;
      case 'generating':
        return <Badge bg="info" className="badge-md me-1">generating</Badge>;
      case 'inspecting':
        return <Badge bg="warning" className="badge-md me-1">inspecting</Badge>;
      case 'requesting availability':
        return <Badge bg="secondary" className="badge-md me-1">requesting availability</Badge>;
      case 'deadline passed':
        return <Badge bg="dark" className="badge-md me-1">deadline passed</Badge>;
      default: // Not available
        return <Badge bg="danger" className="badge-md me-1">not available</Badge>;
    }
  };

  const renderRequests = (cellContent) => (
    <p>
      <span>
        Open shifts:
      </span>
      <span className="ms-1">
        {cellContent.open}
      </span>
      <br />
      <span>
        Other requests:
      </span>
      <span className="ms-1">
        {cellContent['other requests']}
      </span>
    </p>
  );

  const columns = [
    {
      dataField: 'week',
      text: 'Week',
      sort: false,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerAlign: 'center',
      align: 'center',
      formatter: (cellContent) => renderStatus(cellContent),
    },
    {
      dataField: 'requests',
      text: 'Requests',
      formatter: (cellContent) => renderRequests(cellContent),
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      keyField="week"
      data={scheduleStatus}
      columns={columns}
      wrapperClasses="table-responsive"
      headerClasses="thead-light"
    />
  );
};

export default ScheduleStatusTable;
