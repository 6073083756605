import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';

import app from '../firebase';

const AuthContext = React.createContext();

export const useAuth = () => (
  useContext(AuthContext)
);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserToken, setCurrentUserToken] = useState();
  const [loading, setLoading] = useState(true);

  const auth = getAuth(app);

  const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password);

  const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const logout = () => signOut(auth);

  const resetPassword = (email) => sendPasswordResetEmail(auth, email);

  const updateEmail = (email) => currentUser.updateEmail(email);

  const updatePassword = (password) => currentUser.updatePassword(password);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      if (user && user !== null) {
        setCurrentUser(user);
        user.getIdTokenResult()
          .then((idTokenResult) => {
            console.log('token refresh');
            setCurrentUserToken(idTokenResult);
          });
      } else {
        setCurrentUser();
        setCurrentUserToken();
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = useMemo(() => ({
    currentUser,
    currentUserToken,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  }), [currentUser, currentUserToken]);

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
