import React from 'react';
import ReactDOM from 'react-dom';

import {
  Modal,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import { useAuth } from '../../contexts/AuthContext';

import {
  deleteBranch,
} from '../../api/post';

const DeleteBranchModal = (props) => {
  const { currentUserToken } = useAuth();
  const {
    isShowing,
    hide,
    reload,
    branch,
  } = props;

  const handleDelete = (e) => {
    e.preventDefault();

    if (!currentUserToken) {
      console.log('error');
      return;
    }

    deleteBranch(
      currentUserToken,
      branch,
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  return (
    ReactDOM.createPortal(
      <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
        <Modal.Header>
          <Modal.Title className="h6">Delete branch?</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                Are you sure you want to delete this branch?
                All shifts for this branch will be lost.
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>,
      document.body,
    )
  );
};

export default DeleteBranchModal;
