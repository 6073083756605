import React, { useState } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import DeleteShiftModal from './DeleteShiftModal';

import useModal from '../../hooks/useModal';

import {
  splitPeopleElements,
  splitArrayElements,
  parseTime,
  renderNoData,
} from '../../utils/helper';

const ShiftsTable = (props) => {
  const { shifts, reload } = props;

  const [selected, setSelected] = useState();
  const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

  const openDeleteModal = (row) => {
    console.log(row);
    setSelected(row);
    toggleDeleteModal();
  };

  const renderButtons = (row) => (
    <>
      <Button variant="warning" className="m-1" size="sm" onClick={() => console.log('click')}><FontAwesomeIcon icon={faEdit} /></Button>
      <Button variant="danger" className="m-1" size="sm" onClick={() => openDeleteModal(row)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
    </>
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'position',
      text: 'Position',
      sort: true,
    },
    {
      dataField: 'time',
      text: 'Time',
      formatter: (_, row) => parseTime(row.start, row.end, row.endsNextDay),
    },
    {
      dataField: 'capacity',
      text: 'Capacity',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'center',
      align: 'center',
      formatter: (_, row) => renderButtons(row),
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <b>Break: </b>
          {row.break}
        </span>
        <br />
        <span>
          <b>Hard requirements: </b>
          {splitArrayElements(row.hardRequirements)}
        </span>
        <br />
        <span>
          <b>Soft requirements: </b>
          {splitArrayElements(row.requirements)}
        </span>
        <br />
        <span>
          <b>Fixed people: </b>
          {splitPeopleElements(row.fixed)}
        </span>
      </div>
    ),
  };

  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 1)}
        caption="Monday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 2)}
        caption="Tuesday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 3)}
        caption="Wednesday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 4)}
        caption="Thursday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 5)}
        caption="Friday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 6)}
        caption="Saturday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={shifts.filter((x) => x.day === 7)}
        caption="Sunday"
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        noDataIndication={renderNoData}
        expandRow={expandRow}
      />
      <DeleteShiftModal
        isShowing={isShowingDeleteModal}
        hide={toggleDeleteModal}
        reload={reload}
        data={selected}
      />
    </>
  );
};

export default ShiftsTable;
