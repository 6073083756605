import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import {
  Modal,
  Form,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import DismisssableAlert from '../DismissableAlert';
import useAlert from '../../hooks/useAlert';

import { useAuth } from '../../contexts/AuthContext';

import {
  addBranch,
} from '../../api/post';

const AddBranchModal = (props) => {
  const { isShowing, hide, reload } = props;

  if (!isShowing) {
    return null;
  }

  const {
    isShowing: isShowingAlert,
    message,
    toggle,
  } = useAlert();

  const { currentUserToken } = useAuth();
  const [branch, setBranch] = useState('');

  const handleAdd = (e) => {
    e.preventDefault();

    if (!currentUserToken) {
      console.log('error');
      return;
    }

    addBranch(
      currentUserToken,
      branch,
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  return (
    ReactDOM.createPortal(
      <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
        <Form onSubmit={handleAdd}>
          <Modal.Header>
            <Modal.Title className="h6">Add branch</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={hide} />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <DismisssableAlert
                  variant="danger"
                  message={message}
                  show={isShowingAlert}
                  toggle={toggle}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Branch:</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Example Branch"
                    value={branch}
                    onChange={(event) => setBranch(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Add
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={hide}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>,
      document.body,
    )
  );
};

export default AddBranchModal;
