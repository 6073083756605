/* eslint-disable */
import React, { useState } from 'react';

import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  InputGroup
} from '@themesberg/react-bootstrap';

// import LoadingOverlay from 'react-loading-overlay';
import Datetime from 'react-datetime';
import Select from 'react-select';
// import axios from 'axios';

import { useAuth } from "../../contexts/AuthContext";

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

import {
  addShift,
} from '../../api/post';


const AddShiftsModal = (props) => {
  const {
    isShowing,
    hide,
    reload,
    selectedDate,
    branch,
    company,
    team,
  } = props;

  if (!company || !team || !team || !selectedDate || !branch) {
    return null;
  }

  const positions = company.positions.map(x => ({
    label: x,
    value: x
  }))

  const employees = team.map(x => ({
    label: x['name'],
    value: x['email']
  }))

  const skills = company.skills.map(x => ({
    label: x,
    value: x
  }))

  const { currentUserToken } = useAuth();
  const [name, setName] = useState('');
  const [position, setPosition] = useState({});
  const [day, setDay] = useState([]);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [endsNextDay, setEndsNextDay] = useState(false);
  const [break_, setBreak_] = useState('');
  const [capacity, setCapacity] = useState('');
  const [fixedPeople, setFixedPeople] = useState([]);
  const [hardRequirements, setHardRequirements] = useState([]);
  const [requirement1Skill, setRequirement1Skill] = useState('');
  const [requirement1Amount, setRequirement1Amount] = useState('');
  const [requirement2Skill, setRequirement2Skill] = useState('');
  const [requirement2Amount, setRequirement2Amount] = useState('');
  const [requirement3Skill, setRequirement3Skill] = useState('');
  const [requirement3Amount, setRequirement3Amount] = useState('');
  const [requirement4Skill, setRequirement4Skill] = useState('');
  const [requirement4Amount, setRequirement4Amount] = useState('');

  const clearForm = () => {
    setName('');
    setPosition({});
    setDay([]);
    setStart('');
    setEnd('');
    setEndsNextDay(false);
    setBreak_('');
    setCapacity('');
    setFixedPeople([]);
    setHardRequirements([]);
    setRequirement1Skill('');
    setRequirement1Amount('');
    setRequirement2Skill('');
    setRequirement2Amount('');
    setRequirement3Skill('');
    setRequirement3Amount('');
    setRequirement4Skill('');
    setRequirement4Amount('');
  }

  const handleAdd = (e) => {
    e.preventDefault();

    const softRequirements = [
      {
        skill: requirement1Skill,
        amount: requirement1Amount,
      },
      {
        skill: requirement2Skill,
        amount: requirement2Amount,
      },
      {
        skill: requirement3Skill,
        amount: requirement3Amount,
      },
      {
        skill: requirement4Skill,
        amount: requirement4Amount,
      },
    ];

    addShift(
      currentUserToken,
      name,
      position ? position.value : null,
      day.map((x) => x.value),
      start,
      end,
      endsNextDay,
      break_,
      capacity ? parseInt(capacity) : null,
      fixedPeople.map((x) => x.value),
      hardRequirements.map((x) => x.value),
      softRequirements,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
      branch,
    ).then((res) => {
      console.log(res);
      reload();
      hide();
      clearForm();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log(error);
      console.log('Oops, an error occured. Please try again.');
    });

  }

  return (
    <Modal as={Modal.Dialog} size="xl" centered show={isShowing} onHide={hide}>
      <Form onSubmit={handleAdd}>
        <Modal.Header>
          <Modal.Title className="h6">Add shift</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Example Branch"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Position</Form.Label>
                <Select
                  required
                  options={positions}
                  value={position}
                  onChange={(event) => setPosition(event)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Day</Form.Label>
                <Select
                  required
                  defaultValue={[]}
                  isMulti
                  name="days"
                  options={[
                    { label: 'Monday', value: 0 },
                    { label: 'Tuesday', value: 1 },
                    { label: 'Wednesday', value: 2 },
                    { label: 'Thursday', value: 3 },
                    { label: 'Friday', value: 4 },
                    { label: 'Saturday', value: 5 },
                    { label: 'Sunday', value: 6 }
                  ]}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={day}
                  onChange={(event) => setDay(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Start</Form.Label>
                <Datetime
                  required
                  closeOnSelect={false}
                  dateFormat={false}
                  // timeFormat="h:mm A"
                  timeFormat="HH:mm"
                  timeConstraints={{ minutes: { step: 15 } }}
                  inputProps={{ readOnly: true }}
                  value={start}
                  onChange={(event) => setStart(event.format('HH:mm'))}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>End</Form.Label>
                <Datetime
                  required
                  closeOnSelect={false}
                  dateFormat={false}
                  // timeFormat="h:mm A"
                  timeFormat="HH:mm"
                  timeConstraints={{ minutes: { step: 15 } }}
                  inputProps={{ readOnly: true }}
                  value={end}
                  onChange={(event) => setEnd(event.format('HH:mm'))}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>*</Form.Label>
                <Form.Check
                  label="Ends next day"
                  checked={endsNextDay}
                  onChange={(event) => setEndsNextDay(event.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Break</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min="0"
                  step="15"
                  placeholder="#"
                  value={break_}
                  onChange={(event) => setBreak_(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Capacity</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min="0"
                  step="1"
                  placeholder="#"
                  value={capacity}
                  onChange={(event) => setCapacity(event.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Fixed people(optional)</Form.Label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="employees"
                  options={employees}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={fixedPeople}
                  onChange={(event) => setFixedPeople(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Hard Requirements</Form.Label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="skills"
                  options={skills}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={hardRequirements}
                  onChange={(event) => setHardRequirements(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Requirements-soft**(optional)</Form.Label>
                <InputGroup>
                  <InputGroup.Text>(1)</InputGroup.Text>
                  <Form.Select
                    value={requirement1Skill}
                    onChange={(event) => setRequirement1Skill(event.target.value)}
                  >
                    <option defaultValue>Choose skill...</option>
                    {skills.map(x => <option key={x.value}>{x.value}</option>)}
                  </Form.Select>
                  <InputGroup.Text>At least:</InputGroup.Text>
                  <Form.Control
                    className="form-control"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="#"
                    value={requirement1Amount}
                    onChange={(event) => setRequirement1Amount(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>(2)</InputGroup.Text>
                  <Form.Select
                    value={requirement2Skill}
                    onChange={(event) => setRequirement2Skill(event.target.value)}
                  >
                    <option defaultValue>Choose skill...</option>
                    {skills.map(x => <option key={x.value}>{x.value}</option>)}
                  </Form.Select>
                  <InputGroup.Text>At least:</InputGroup.Text>
                  <Form.Control
                    className="form-control"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="#"
                    value={requirement2Amount}
                    onChange={(event) => setRequirement2Amount(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>(3)</InputGroup.Text>
                  <Form.Select
                    value={requirement3Skill}
                    onChange={(event) => setRequirement3Skill(event.target.value)}
                  >
                    <option defaultValue>Choose skill...</option>
                    {skills.map(x => <option key={x.value}>{x.value}</option>)}
                  </Form.Select>
                  <InputGroup.Text>At least:</InputGroup.Text>
                  <Form.Control
                    className="form-control"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="#"
                    value={requirement3Amount}
                    onChange={(event) => setRequirement3Amount(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>(4)</InputGroup.Text>
                  <Form.Select
                    value={requirement4Skill}
                    onChange={(event) => setRequirement4Skill(event.target.value)}
                  >
                    <option defaultValue>Choose skill...</option>
                    {skills.map(x => <option key={x.value}>{x.value}</option>)}
                  </Form.Select>
                  <InputGroup.Text>At least:</InputGroup.Text>
                  <Form.Control
                    className="form-control"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="#"
                    value={requirement4Amount}
                    onChange={(event) => setRequirement4Amount(event.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            Add
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  )
}

export default AddShiftsModal;