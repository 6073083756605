import React from 'react';
import {
  Nav,
  Image,
  Navbar as NavBarBootstrap,
  Dropdown,
  Container,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faCog,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

// import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
// import axios from 'axios';
import { Link } from 'react-router-dom';
// import { apiURL } from '../api/api';

import profile from './assets/profile-picture.jpg';

import routes from '../routes';
import { useAuth } from '../contexts/AuthContext';
// import { Loader } from './Loader';

// const Notification = (props) => {
//   const {
//     text,
//     title,
//   } = props;

//   return (
//     <ListGroup.Item action href="#" className="border-bottom border-light">
//       <Row className="align-items-center">
//         <Col className="ps-2 ms--2">
//           <div className="d-flex justify-content-between align-items-center">
//             <div>
//               <h4 className="h6 mb-0 text-small">{title}</h4>
//             </div>
//             {/* <div className="text-end">
//               <small>{timestamp}</small>
//             </div> */}
//           </div>
//           <p className="font-small mt-1 mb-0">{text}</p>
//         </Col>
//       </Row>
//     </ListGroup.Item>
//   );
// };

const Navbar = () => {
  const { logout } = useAuth();
  // const [notifications, setNotifications] = useState([]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      /* eslint-disable-next-line */
      console.log(err);
    }
  };

  return (
    <NavBarBootstrap variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <Nav className="align-items-center ms-auto">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  <span className="icon-badge rounded-circle unread-notifications" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-dark dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                {/* <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary
                  fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
                  {notificationsLoaded ?
                    <>
                      {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}
                      <Dropdown.Item className="text-center text-primary fw-bold py-3">
                        View all
                      </Dropdown.Item>
                    </>
                    :
                    <Loader />
                  }
                </ListGroup> */}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={profile} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">My Account</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-dark user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" as={Link} to={routes.SettingsPage.path}>
                  <FontAwesomeIcon icon={faCog} className="me-2" />
                  Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </NavBarBootstrap>
  );
};

export default Navbar;

// export default (props) => {
//   const { logout, currentUserToken } = useAuth()
//   const [notifications, setNotifications] = useState([]);
//   const [notificationsLoaded, setNotificationsLoaded] = useState(false);

//   useEffect(() => {
//     const fetchNotifications = async (token) => {
//       try {
//         const { data } = await axios({
//           method: 'get', //you can set what request you want to be
//           url: apiURL('notifications'),
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: 'Bearer ' + token
//           },
//           params: {
//             amount: 5
//           }
//         });
//         setNotifications(data)
//       } catch (error) {
//         console.log(error)
//       }
//     };

//     if (currentUserToken) {
//       fetchNotifications(currentUserToken['token']).then(() => setNotificationsLoaded(true));
//     }
//   }, [currentUserToken]);

//   const Notification = (props) => {
//     const { text, title } = props;

//     return (
//       <ListGroup.Item action href='#' className="border-bottom border-light">
//         <Row className="align-items-center">
//           <Col className="ps-2 ms--2">
//             <div className="d-flex justify-content-between align-items-center">
//               <div>
//                 <h4 className="h6 mb-0 text-small">{title}</h4>
//               </div>
//               {/* <div className="text-end">
//                 <small>{timestamp}</small>
//               </div> */}
//             </div>
//             <p className="font-small mt-1 mb-0">{text}</p>
//           </Col>
//         </Row>
//       </ListGroup.Item>
//     );
//   };

//   // Logout handling
//   const [error, setError] = useState("")

//   const handleLogout = async () => {
//     setError("")

//     try {
//       await logout()
//       // history.push("/sign-in")
//     } catch {
//       setError("Failed to log out")
//       console.log(error)
//     }
//   }

//   return (
//     <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
//       <Container fluid className="px-0">
//         <div className="d-flex justify-content-between w-100">
//           <div className="d-flex align-items-center">
//           </div>
//           <Nav className="align-items-center">
//             <Dropdown as={Nav.Item} >
//               <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
//                 <span className="icon icon-sm">
//                   <FontAwesomeIcon icon={faBell} className="bell-shake" />
//                   <span className="icon-badge rounded-circle unread-notifications" />
//                 </span>
//               </Dropdown.Toggle>
//               <Dropdown.Menu className="border-dark dashboard-dropdown
// notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
//                 <ListGroup className="list-group-flush">
//                   <Nav.Link href="#" className="text-center text-primary
// fw-bold border-bottom border-light py-3">
//                     Notifications
//                   </Nav.Link>

//                   {notificationsLoaded ?
//                     <>
//                       {notifications.map(n => <Notification
// key={`notification-${n.id}`} {...n} />)}
//                       <Dropdown.Item className="text-center text-primary fw-bold py-3">
//                         View all
//                       </Dropdown.Item>
//                     </>
//                     :
//                     <Loader />
//                   }
//                 </ListGroup>
//               </Dropdown.Menu>
//             </Dropdown>

//             <Dropdown as={Nav.Item}>
//               <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
//                 <div className="media d-flex align-items-center">
//                   <Image src={ProfilePicture} className="user-avatar md-avatar rounded-circle" />
//                   <div className="media-body ms-2 text-dark align-items-
// center d-none d-lg-block">
//                     <span className="mb-0 font-small fw-bold">My Account</span>
//                   </div>
//                 </div>
//               </Dropdown.Toggle>
//               <Dropdown.Menu className="border-dark user-dropdown dropdown-menu-right mt-2">
//                 <Dropdown.Item className="fw-bold">
//                   <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
//                 </Dropdown.Item>
//                 <Dropdown.Item className="fw-bold" as={Link} to={Routes.Settings.path}>
//                   <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
//                 </Dropdown.Item>
//                 <Dropdown.Divider />

//                 <Dropdown.Item className="fw-bold" onClick={handleLogout}>
//                   <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
//                 </Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>
//           </Nav>
//         </div>
//       </Container>
//     </Navbar>
//   );
// };
