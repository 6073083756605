import React from 'react';

export const splitPeopleElements = (people) => {
  if (Array.isArray(people) && people.length) {
    return <span>{people.map((a) => a.name).join(', ')}</span>;
  }
  return <span>-</span>;
};

export const splitArrayElements = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    return <span>{arr.join(', ')}</span>;
  }
  return <span>-</span>;
};

export const parseTime = (start, end, endsNextDay) => {
  const time = endsNextDay ? `${start} - ${end} (+1)` : `${start} - ${end}`;
  return time;
};

export const renderNoData = () => <span>No Data</span>;
