import axios from 'axios';

const fetcher = async (url, token, args = null) => {
  const authString = `Bearer ${token}`;
  if (args) {
    const res = await axios.get(
      url,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authString,
        },
        params: args,
      },
    );
    return res.data;
  }

  const res = await axios.get(
    url,
    {
      headers: {
        Authorization: authString,
      },
    },
  );
  return res.data;
};

export default fetcher;
