/* eslint-disable */
import React from 'react';
import {
  Card,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import Content from '../Content';
import TitleBar from '../TitleBar';

import Branches from './Branches';
import Positions from './Positions';
import Skills from './Skills';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../contexts/AuthContext';

import {
  getCompany,
} from '../../api/get';

import AddBranchModal from './AddBranchModal';

import useModal from '../../hooks/useModal';

const CompanyPage = () => {
  const { currentUserToken } = useAuth();

  const { data: company, error: errorCompany, mutate } = getCompany(currentUserToken);

  console.log(company);

  const reload = () => {
    mutate();
  }

  return (
    <Content>
      <TitleBar title="Company" />

      <hr />

      <Row>
        <Col lg={4}>
          <Branches
            company={company}
            error={errorCompany}
            reload={reload} />
        </Col>

        <Col lg={4}>
          <Positions
            company={company}
            error={errorCompany}
            reload={reload} />
        </Col>

        <Col lg={4}>
          <Skills
            company={company}
            error={errorCompany}
            reload={reload} />
        </Col>
      </Row>

    </Content>
  );
};

export default CompanyPage;
