import { useState } from 'react';

const useAlert = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [message, setMessage] = useState('');

  function toggle(msg = '') {
    setMessage(msg);
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    message,
    toggle,
  };
};

export default useAlert;
