/* eslint-disable */
import React, { useState } from 'react';

import WeekButtons from '../WeekButtons';
import Content from '../Content';
import TitleBar from '../TitleBar';

import { getToday } from '../../utils/moment-utils';

import Availabilities from './Availablilities';

const AvailabilitiesPage = () => {
  const [selectedDate, setSelectedDate] = useState(getToday());

  return (
    <Content>
      <TitleBar title="Availabilities" status="None">
        <WeekButtons 
          selectedDate={selectedDate} 
          setSelectedDate={setSelectedDate} />
      </TitleBar>

      <hr/>
      
      <Availabilities selectedDate={selectedDate} />

    </Content>
  );
};

export default AvailabilitiesPage;
