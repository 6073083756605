/* eslint-disable */
import React from 'react';
import { Card, Row, Col, Button } from '@themesberg/react-bootstrap';

import {
  getTeam,
} from '../../api/get';

import AddTeamModal from './AddTeamModal';
import { useAuth } from '../../contexts/AuthContext';
import TeamTable from './TeamTable';
import { LoadingCard } from '../Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import useModal from '../../hooks/useModal';

const Team = (props) => {
  const { company } = props;

  const { currentUserToken } = useAuth();
  const { data: team, error: errorTeam, mutate: mutateTeam } = getTeam(currentUserToken);

  const { isShowing: isShowingAddModal, toggle: toggleAddModal } = useModal();

  if (!team || errorTeam) {
    return <LoadingCard title="MY TEAM" />;
  }

  const reload = () => {
    mutateTeam();
  }

  return (
    <>
      <Card border="grey" className="border-3 shadow-sm mb-4">
        <Card.Header className='d-flex justify-content-between'>
          <b>MY TEAM</b>
          <Button variant="primary" size="sm" onClick={toggleAddModal}>
            <span>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add
            </span>
          </Button>
        </Card.Header>
        <Card.Body className="pb-0">
          <TeamTable
            team={team}
            reload={reload}
          />
        </Card.Body>
      </Card>
      <AddTeamModal
        isShowing={isShowingAddModal}
        hide={toggleAddModal}
        reload={reload}
        company={company}
      />
    </>
  )
};

export default Team;
