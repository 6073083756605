// React
import React from 'react';
import { Button, ButtonGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { getDateNextWeek, getDatePreviousWeek, getIsoWeek } from '../utils/moment-utils';

const WeekButtons = (props) => {
  const { selectedDate, setSelectedDate } = props;

  const nextWeek = () => setSelectedDate(getDateNextWeek(selectedDate));
  const previousWeek = () => setSelectedDate(getDatePreviousWeek(selectedDate));

  return (
    <ButtonGroup>
      <Button variant="primary" size="sm" onClick={previousWeek}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <Button variant="outline-primary" size="sm">
        {`week ${getIsoWeek(selectedDate)}`}
      </Button>
      <Button variant="primary" size="sm" onClick={nextWeek}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </ButtonGroup>
  );
};

export default WeekButtons;
