/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  faBriefcase,
  faUserFriends,
  faCalendarAlt,
  faCalendarCheck,
  faClipboardList,
  faCalendarPlus,
} from '@fortawesome/free-solid-svg-icons';

import SimpleBar from 'simplebar-react';
import { useLocation, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import routes from '../routes';

import logo from './assets/logo-full-white.svg';

const CollapsableNavItem = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    eventKey,
    title,
    icon,
    children = null,
  } = props;
  const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

  return (
    <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
          <span>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />
            </span>
            <span className="sidebar-text">{title}</span>
          </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
          <Nav className="flex-column">
            {children}
          </Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const NavItem = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    setShow,
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    badgeBg = 'secondary',
    badgeColor = 'primary',
  } = props;
  const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
  const navItemClassName = link === pathname ? 'active' : '';
  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon
            ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />
              </span>
            )
            : null }
          {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
          <span className="sidebar-text">{title}</span>
        </span>
        {badgeText ? (
          <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};

const Sidebar = () => {
  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={routes.DashboardCompanyPage.path}>
          <Image src={logo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">

            <Nav className="flex-column pt-3 pt-md-0">
              <Navbar.Brand as={Link} to={routes.DashboardCompanyPage.path}>
                <Image src={logo} className="navbar-brand-light" />
              </Navbar.Brand>

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem title="Overview" link={routes.DashboardCompanyPage.path} icon={faChartPie} setShow={setShow} />
              <NavItem title="Company" link={routes.CompanyPage.path} icon={faBriefcase} setShow={setShow} />
              <NavItem title="Team" link={routes.TeamPage.path} icon={faUserFriends} setShow={setShow} />

              <CollapsableNavItem eventKey="create/" title="Schedule Creation" icon={faCalendarPlus}>
                <NavItem title="Shifts" link={routes.ShiftsPage.path} icon={faClipboardList} setShow={setShow} />
                <NavItem title="Availabilities" link={routes.AvailabilitiesPage.path} icon={faCalendarCheck} setShow={setShow} />
                <NavItem title="Schedule" link={routes.SchedulePage.path} icon={faCalendarAlt} setShow={setShow} />
              </CollapsableNavItem>

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;
