import React from 'react';

import {
  Modal,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import { useAuth } from '../../contexts/AuthContext';

import {
  clearShifts,
} from '../../api/post';

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

const ClearShiftsModal = (props) => {
  const { currentUserToken } = useAuth();
  const {
    isShowing,
    hide,
    reload,
    branch,
    selectedDate,
  } = props;

  const handleDelete = (e) => {
    e.preventDefault();

    if (!currentUserToken || !branch || !selectedDate) {
      console.log('error');
      return;
    }

    clearShifts(
      currentUserToken,
      branch,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  return (
    <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
      <Modal.Header>
        <Modal.Title className="h6">Clear?</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={hide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>You are about to clear all the shifts for this week. Would you like to continue?</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          Clear
        </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClearShiftsModal;
