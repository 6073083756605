import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Col, Row, Form, Card, Button, FormCheck, Container, InputGroup,
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import routes from '../../routes';
import BgImage from './assets/signin.svg';

const SignupCompanyPage = () => (
  <main>
    <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
      <Container>
        <p className="text-center">
          <Card.Link as={Link} to={routes.AccountTypePage.path} className="text-gray-700">
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" />
            {' '}
            Back to choose account type
          </Card.Link>
        </p>
        <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
          <Col xs={12} className="d-flex align-items-center justify-content-center">
            <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h3 className="mb-0">Create a company account</h3>
              </div>
              <Form className="mt-4">
                <Form.Group id="company" className="mb-4">
                  <Form.Label>Company</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control autoFocus required type="text" placeholder="Company" />
                  </InputGroup>
                </Form.Group>
                <Form.Group id="email" className="mb-4">
                  <Form.Label>Your Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control autoFocus required type="email" placeholder="example@company.com" />
                  </InputGroup>
                </Form.Group>
                <Form.Group id="password" className="mb-4">
                  <Form.Label>Your Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control required type="password" placeholder="Password" />
                  </InputGroup>
                </Form.Group>
                <Form.Group id="confirmPassword" className="mb-4">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control required type="password" placeholder="Confirm Password" />
                  </InputGroup>
                </Form.Group>
                <FormCheck type="checkbox" className="d-flex mb-4">
                  <FormCheck.Input required id="terms" className="me-2" />
                  <FormCheck.Label htmlFor="terms">
                    I agree to the
                    {' '}
                    <Card.Link>terms and conditions</Card.Link>
                  </FormCheck.Label>
                </FormCheck>

                <Button variant="primary" type="submit" className="w-100">
                  Sign up
                </Button>
              </Form>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  Already have an account?
                  <Card.Link as={Link} to={routes.SigninPage.path} className="fw-bold">
                    {' Login here '}
                  </Card.Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </main>
);
export default SignupCompanyPage;
