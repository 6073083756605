import React from 'react';

import {
  Card,
  Image,
} from '@themesberg/react-bootstrap';
import LoadingSpinner from './assets/loading-spinner.svg';

const Loader = () => (
  <div className="bg-transparent flex-column justify-content-center align-items-center text-center">
    <Image className="loader-element" src={LoadingSpinner} height={150} />
  </div>
);

export default Loader;

export const LoadingCard = (props) => {
  const { title } = props;

  return (
    <Card border="grey" className="border-3 shadow-sm mb-4">
      <Card.Header className="d-flex justify-content-between">
        <b>{title}</b>
      </Card.Header>
      <Card.Body>
        <div className="bg-transparent flex-column justify-content-center align-items-center text-center">
          <Image className="loader-element" src={LoadingSpinner} height={150} />
        </div>
      </Card.Body>
    </Card>
  );
};
