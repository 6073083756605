const routes = {
  // Error Pages
  NotFoundPage: { path: '/404' },
  ServerErrorPage: { path: '/500' },

  // Dashboard Company Pages
  DashboardCompanyPage: { path: '/' },
  CompanyPage: { path: '/company' },
  TeamPage: { path: '/team' },
  SchedulePage: { path: '/create/schedule' },
  ShiftsPage: { path: '/create/shifts' },
  AvailabilitiesPage: { path: '/create/availabilities' },
  // Inspection: { path: '/create/inspection' },
  SettingsPage: { path: '/settings' },

  // Dashboard Employee Pages
  DashboardEmployee: { path: '/x' },
  About: { path: '/x/about' },

  // Auth Pages
  SigninPage: { path: '/sign-in' },
  SignupPage: { path: '/sign-up-employee' },
  SignupCompanyPage: { path: '/sign-up-company' },
  AccountTypePage: { path: '/account-type' },
  ForgotPasswordPage: { path: '/forgot-password' },
  ResetPasswordPage: { path: '/reset-password' },
};

export default routes;
