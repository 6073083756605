/* eslint-disable */
import React, { useState } from 'react';
import { Button } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteBranchModal from './DeleteBranchModal';

import BootstrapTable from 'react-bootstrap-table-next';

import useModal from '../../hooks/useModal';

const BranchesTable = (props) => {
  const { company, reload } = props;

  const [selected, setSelected] = useState();
  const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();


  const openDeleteModal = (name) => {
    console.log(name);
    setSelected(name);
    toggleDeleteModal();
  }

  const branches = company.branches.map((x, i) => ({
    id: i,
    name: x,
  }));

  const renderButtons = (_, row) => (
    <Button variant="danger" className="m-1" size="sm" onClick={() => openDeleteModal(row['name'])}>
      <span>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
        Delete
      </span>
    </Button>
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'right',
      align: 'right',
      formatter: renderButtons,
    },
  ];

  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={branches}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
        // noDataIndication={() => <NoDataIndication}
      />
      <DeleteBranchModal
        isShowing={isShowingDeleteModal}
        hide={toggleDeleteModal}
        reload={reload}
        branch={selected}
      />
    </>
  );
};

export default BranchesTable;
