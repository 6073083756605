import useSWR from 'swr';

import fetcher from './swr';
import apiURL from '../api';

export const getScheduleStatus = (currentUserToken, week, year) => {
  const url = apiURL(`dashboard/schedule-status?week=${week}&year=${year}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getCompany = (currentUserToken) => {
  const url = apiURL('company');
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getTeam = (currentUserToken) => {
  const url = apiURL('team');
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getShifts = (currentUserToken, week, year, branch) => {
  const url = apiURL(`shifts?week=${week}&year=${year}&branch=${branch}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getAvailabilities = (currentUserToken, week, year) => {
  const url = apiURL(`availabilities?week=${week}&year=${year}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getProcessing = (currentUserToken, week, year) => {
  const url = apiURL(`processing?week=${week}&year=${year}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getScheduleInspection = (currentUserToken, week, year, branch) => {
  const url = !branch ? apiURL(`inspection?week=${week}&year=${year}`) : apiURL(`inspection?week=${week}&year=${year}&branch=${branch}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};

export const getSchedule = (currentUserToken, week, year, branch) => {
  const url = !branch ? apiURL(`schedule?week=${week}&year=${year}`) : apiURL(`schedule?week=${week}&year=${year}&branch=${branch}`);
  return useSWR(currentUserToken ? [url, currentUserToken.token] : null, fetcher);
};
