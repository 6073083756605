/* eslint-disable */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import {
  Modal,
  Form,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useAuth } from '../../contexts/AuthContext';

import {
  addPerson,
} from '../../api/post';

const AddPeopleModal = (props) => {
  const {
    isShowing,
    hide,
    team,
    selected,
    reloadSchedule,
  } = props;

  if (!isShowing || !team || !selected) {
    return null;
  }

  const { currentUserToken } = useAuth();
  const [persons, setPersons] = useState([]);

  const employees = team.map(x => ({
    label: x.name,
    value: x.email,
    disabled: selected.people.some((element) => element.email === x.email) ? true : false
  }))

  const handleAdd = (e) => {
    e.preventDefault();

    if (!currentUserToken || !selected) {
      console.log('error');
      return;
    }

    addPerson(
      currentUserToken,
      persons.map((x) => x.value),
      selected.id,
    ).then((res) => {
      console.log(res);
      reloadSchedule();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };



  return (
    ReactDOM.createPortal(
      <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
        <Form onSubmit={handleAdd}>
          <Modal.Header>
            <Modal.Title className="h6">Add branch</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={hide} />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Add people</Form.Label>
                  <Select
                    required
                    isMulti
                    name="people"
                    options={employees}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={persons}
                    onChange={(event) => setPersons(event)}
                    isOptionDisabled={(option) => option.disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Add
            </Button>
            <Button variant="link" className="text-gray ms-auto" onClick={hide}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>,
      document.body,
    )
  );
};

export default AddPeopleModal;
