import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import Preloader from './Preloader';

const RouteWithLoaderPrivate = ({ component: Component }) => {
  const [loaded, setLoaded] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (currentUser) {
    return (
      <Navigate to="/" />
    );
  }
  return (
    <>
      <Preloader show={!loaded} />
      <Component />
    </>
  );
};

export default RouteWithLoaderPrivate;
