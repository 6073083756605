/* eslint-disable */
import React, { useState } from 'react';

import BootstrapTable, { ROW_SELECT_MULTIPLE } from 'react-bootstrap-table-next';

import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from "../../contexts/AuthContext";

import {
  saveTemplate,
  loadTemplate,
  deleteTemplate,
} from '../../api/post';

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

const TemplatesModal = (props) => {
  const {
    isShowing,
    hide,
    reload,
    branch,
    selectedDate,
    company,
  } = props;

  if (!company) {
    return null;
  }

  const { currentUserToken } = useAuth();

  const [templateName, setTemplateName] = useState('');

  const templates = company.templates.map((x, i) => ({
    id: i,
    name: x
  }));

  const handleLoad = (e, row) => {
    e.preventDefault();

    loadTemplate(
      currentUserToken,
      row.name,
      branch,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  }

  const handleDelete = (e, row) => {
    e.preventDefault();
  
    deleteTemplate(
      currentUserToken,
      row.name,
      branch,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
    ).then((res) => {
      console.log(res);
      reload();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  }

  const renderButtons = (_, row) => (
    <>
      <Button variant="success" className="m-1" size="sm" onClick={(event) => handleLoad(event, row)}>
        <FontAwesomeIcon icon={faDownload} className="me-2" />Load
      </Button>
      <Button variant="danger" className="m-1" size="sm" onClick={(event) => handleDelete(event, row)}>
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" />Delete
      </Button>
    </>
  )

  const columns = [
    {
      dataField: "name",
      text: "Template",
      sort: true
    },
    {
      dataField: 'action',
      text: 'Action',
      headerAlign: 'right',
      align: 'right',
      formatter: renderButtons,
    },
  ]

  const handleSave = (e) => {
    e.preventDefault();

    if (!currentUserToken || !branch || !selectedDate) {
      console.log('error');
      return;
    }

    saveTemplate(
      currentUserToken,
      templateName,
      branch,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
    ).then((res) => {
      console.log(res);
      reload();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
      <Modal.Header>
        <Modal.Title className="h6">Templates</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={hide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSave}>
              <Form.Group className="mb-3">
                <Form.Label>Save as template:</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Template name"
                    value={templateName}
                    onChange={(event) => setTemplateName(event.target.value)}
                  />
                  <Button variant="success" type="submit">
                    Save
                  </Button>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Manage templates: <small>Note: loading a template will overwrite all current shifts</small> </Form.Label>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={templates}
                columns={columns}
                wrapperClasses="table-responsive"
                headerClasses="thead-light"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="text-gray ms-auto" onClick={hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplatesModal;
