/* eslint-disable */
import React, { useState } from 'react';

import {
  Form,
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
} from '@themesberg/react-bootstrap';

import { useAuth } from '../../contexts/AuthContext';

import {
  generate,
} from '../../api/post';

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

const GenerateScheduleModal = (props) => {
  const { currentUserToken } = useAuth();
  const {
    isShowing,
    hide,
    reload,
    selectedDate,
  } = props;

  const [goal, setGoal] = useState('fast');
  const [minimumHours, setMinimumHours] = useState('0');
  const [oneShiftPerDay, setOneShiftPerDay] = useState('Yes');
  const [prioritizeLongShifts, setPrioritizeLongShifts] = useState('No');
  const [maxWaitingTime, setMaxWaitingTime] = useState('10');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!currentUserToken || !selectedDate) {
      console.log('error');
      return;
    }

    generate(
      currentUserToken,
      getIsoWeek(selectedDate),
      getIsoYear(selectedDate),
      goal,
      minimumHours ? parseInt(minimumHours) : null,
      oneShiftPerDay,
      prioritizeLongShifts,
      maxWaitingTime ? parseInt(maxWaitingTime) : null,
    ).then((res) => {
      console.log(res);
      reload();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  const renderMinimumHoursOptions = () => {
    const elements = [];
    for (let i = 0; i < 25; i++) {
      elements.push(<option key={i} value={i}>{i} minutes</option>)
    }
    return elements;
  }

  return (
    <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title className="h6">Generate</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <InputGroup>
                <InputGroup.Text>Goal:</InputGroup.Text>
                <Form.Select
                  value={goal}
                  onChange={(event) => setGoal(event.target.value)}
                >
                  <option value="fast">Fastest (default)</option>
                  <option value="fair">Fair Schedule (Equal Shifts)</option>
                  <option value="fairHours">Fair Schedule (Equal Hours</option>
                  <option value="cost">Cost Saving</option>
                  <option value="empty">Empty (Schedule Manually</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          {goal !== 'empty' &&
            <>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Minimum hours between shifts:</InputGroup.Text>
                    <Form.Select
                      value={minimumHours}
                      onChange={(event) => setMinimumHours(event.target.value)}
                    >
                      {renderMinimumHoursOptions()}
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Employee can only work 1 shift per day:</InputGroup.Text>
                    <Form.Select
                      value={oneShiftPerDay}
                      onChange={(event) => setOneShiftPerDay(event.target.value)}
                    >
                      <option value="Yes">Yes (default)</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Prioritize longer shifts (Beta)</InputGroup.Text>
                    <Form.Select
                      value={prioritizeLongShifts}
                      onChange={(event) => setPrioritizeLongShifts(event.target.value)}
                    >
                      <option value="No">No (default)</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="my-1">
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Max waiting time*</InputGroup.Text>
                    <Form.Select
                      value={maxWaitingTime}
                      onChange={(event) => setMaxWaitingTime(event.target.value)}
                    >
                      <option value="10">10 minutes</option>
                      <option value="15">15 minutes</option>
                      <option value="20">20 minutes</option>
                      <option value="25">25 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="35">35 minutes</option>
                      <option value="40">40 minutes</option>
                      <option value="45">45 minutes</option>
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
            </>
          }
          <small>Note: If there is a previously generated schedule for this week, generating a new schedule will overwrite the previous one. </small>
          <br /> <br />
          <small>* In most cases the algorithm will find an optimal solution before the max time. 10 minutes should be sufficient in most cases, however if this does not yield the desired result, you could try increasing the max waiting time.</small>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type='submit'>
            Generate
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GenerateScheduleModal;
