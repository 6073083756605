import { initializeApp } from 'firebase/app';

const getFirebaseConfig = () => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return {
      apiKey: 'AIzaSyAUOXrr5jUIAlNXp48rMUaP0MEWwgSRb4c',
      authDomain: 'rocketshyft-europe-test.firebaseapp.com',
      projectId: 'rocketshyft-europe-test',
      storageBucket: 'rocketshyft-europe-test.appspot.com',
      messagingSenderId: '741431577909',
      appId: '1:741431577909:web:ec3f935d6bf01d597a507c',
    };
  }

  return {
    apiKey: 'AIzaSyA7zAPUTk1v-zBlfV0bpZnJxhYBQWv921o',
    authDomain: 'rocketshyft-europe.firebaseapp.com',
    databaseURL: 'https://rocketshyft-europe.firebaseio.com',
    projectId: 'rocketshyft-europe',
    storageBucket: 'rocketshyft-europe.appspot.com',
    messagingSenderId: '883273926776',
    appId: '1:883273926776:web:b678f690fd1cd19bf6058d',
  };
};

const firebaseConfig = getFirebaseConfig();

const app = initializeApp(firebaseConfig);

export default app;
