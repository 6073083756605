/* eslint-disable */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import BootstrapTable from 'react-bootstrap-table-next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';

import { useAuth } from '../../contexts/AuthContext';

import { deletePerson } from '../../api/post';

const PeopleModal = (props) => {
  const {
    isShowing,
    hide,
    reloadSchedule,
    selected,
  } = props;

  // useEffect(() => {
  //   console.log(selected);
  //   console.log(isShowing);
  // }, [selected]);

  const { currentUserToken } = useAuth();

  if (!selected) {
    return null;
  }

  const handleDelete = (e, row) => {
    e.preventDefault();

    if (!currentUserToken || !selected) {
      console.log('error');
      return;
    }

    deletePerson(
      currentUserToken,
      selected.id,
      row.email,
    ).then((res) => {
      console.log(res);
      reloadSchedule();
      hide();
    }).catch((err) => {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
      console.log('Oops, an error occured. Please try again.');
    });
  };

  const renderButtons = (_, row) => (
    <Button variant="danger" className="m-1" size="sm" onClick={(e) => handleDelete(e, row)}>
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  );

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      headerAlign: 'center',
      align: 'center',
      formatter: renderButtons,
    },
  ];

  return (
    ReactDOM.createPortal(
      <Modal as={Modal.Dialog} centered show={isShowing} onHide={hide}>
        <Modal.Header>
          <Modal.Title className="h6">People</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={hide} />
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable
            bootstrap4
            keyField="email"
            data={selected.people}
            columns={columns}
            wrapperClasses="table-responsive"
            headerClasses="thead-light"
          // noDataIndication={() => <NoDataIndication />}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>,
      document.body,
    )
  );
};

export default PeopleModal;
