import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';

import { SWRConfig } from 'swr';
import { AuthProvider } from './contexts/AuthContext';

import routes from './routes';

// // Error Pages
import NotFoundPage from './components/error/NotFoundPage';
import ServerErrorPage from './components/error/ServerErrorPage';

// // Dashboard Pages
import DashboardCompanyPage from './components/dashboard/DashboardCompanyPage';
import CompanyPage from './components/company/CompanyPage';
import TeamPage from './components/team/TeamPage';
import ShiftsPage from './components/shifts/ShiftsPage';
import SchedulePage from './components/schedule/SchedulePage';
import AvailabilitiesPage from './components/availabilities/AvailabilitiesPage';
// // import Inspection from "./company/Inspection";
import SettingsPage from './components/settings/SettingsPage';

// // Dashboard Pages Employee
// import DashboardEmployee from "./dashboard/DashboardEmployee";
// import About from "./employee/About";

// // Auth Pages
import SigninPage from './components/auth/SigninPage';
import SignupPage from './components/auth/SignupPage';
import SignupCompanyPage from './components/auth/SignupCompanyPage';
import AccountTypePage from './components/auth/AccountTypePage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
// import ResetPasswordPage from './auth/ResetPasswordPage';

// // Components
import RouteWithLoaderPublic from './components/RouteWithLoaderPublic';
import RouteWithLoaderPrivate from './components/RouteWithLoaderPrivate';
import RouteWithSidebar from './components/RouteWithSidebar';
// import RouteWithSidebarEmployee from "../components/RouteWithSidebarEmployee";

const HomePage = () => (
  <SWRConfig
    value={
      {
        revalidateOnFocus: false,
        revalidateOnMount: true,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
      }
    }
  >
    <AuthProvider>
      <Routes>
        {/* Error Pages */}
        <Route
          path={routes.NotFoundPage.path}
          element={<RouteWithLoaderPublic component={NotFoundPage} />}
        />
        <Route
          path={routes.ServerErrorPage.path}
          element={<RouteWithLoaderPublic component={ServerErrorPage} />}
        />

        {/* Auth Pages */}
        <Route
          path={routes.SigninPage.path}
          element={<RouteWithLoaderPrivate component={SigninPage} />}
        />
        <Route
          path={routes.SignupPage.path}
          element={<RouteWithLoaderPrivate component={SignupPage} />}
        />
        <Route
          path={routes.SignupCompanyPage.path}
          element={<RouteWithLoaderPrivate component={SignupCompanyPage} />}
        />
        <Route
          path={routes.AccountTypePage.path}
          element={<RouteWithLoaderPrivate component={AccountTypePage} />}
        />
        <Route
          path={routes.ForgotPasswordPage.path}
          element={<RouteWithLoaderPrivate component={ForgotPasswordPage} />}
        />

        {/* Company Pages */}
        <Route
          path={routes.DashboardCompanyPage.path}
          element={<RouteWithSidebar component={DashboardCompanyPage} />}
        />
        <Route
          path={routes.CompanyPage.path}
          element={<RouteWithSidebar component={CompanyPage} />}
        />
        <Route
          path={routes.TeamPage.path}
          element={<RouteWithSidebar component={TeamPage} />}
        />
        <Route
          path={routes.ShiftsPage.path}
          element={<RouteWithSidebar component={ShiftsPage} />}
        />
        <Route
          path={routes.AvailabilitiesPage.path}
          element={<RouteWithSidebar component={AvailabilitiesPage} />}
        />
        <Route
          path={routes.SchedulePage.path}
          element={<RouteWithSidebar component={SchedulePage} />}
        />
        <Route
          path={routes.SettingsPage.path}
          element={<RouteWithSidebar component={SettingsPage} />}
        />

        {/* Default Not Found Page */}
        <Route
          path="*"
          element={<RouteWithLoaderPublic component={NotFoundPage} />}
        />
      </Routes>
    </AuthProvider>
  </SWRConfig>
);

export default HomePage;
