/* eslint-disable */
import React, { useState } from 'react';
import { Button, Card } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';

import { splitArrayElements } from '../../utils/helper';

import DeleteTeamModal from './DeleteTeamModal';

import useModal from '../../hooks/useModal';

const TeamTable = (props) => {
  const { team, reload } = props;

  const [selected, setSelected] = useState();
  const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

  const openDeleteModal = (row) => {
    console.log(row);
    setSelected(row);
    toggleDeleteModal();
  }

  const renderStatus = (cellContent) => {
    if (cellContent === 'pending') {
      return (
        <span className="text-warning">
          {cellContent}
        </span>
      );
    }
    return (
      <span className="text-success">
        {cellContent}
      </span>
    );
  };

  const renderButtons = (row) => (
    <>
      <Button variant="warning" className="m-1" size="sm" onClick={() => console.log('clicked')}><FontAwesomeIcon icon={faEdit} /></Button>
      <Button variant="danger" className="m-1" size="sm" onClick={() => openDeleteModal(row)} ><FontAwesomeIcon icon={faTrashAlt} /></Button>
    </>
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'branch',
      text: 'Branch',
      sort: true,
      formatter: (cellContent) => splitArrayElements(cellContent),
    },
    {
      dataField: 'position',
      text: 'Position',
      sort: true,
      formatter: (cellContent) => splitArrayElements(cellContent),
    },
    {
      dataField: 'skill',
      text: 'Skills',
      sort: true,
      formatter: (cellContent) => splitArrayElements(cellContent),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: (cellContent) => renderStatus(cellContent),
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: true,
      headerAlign: 'right',
      align: 'right',
      formatter: (_, row) => renderButtons(row),
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
      <div>
        <span>
          <b>Salary (hourly): </b>
          {row['salary']}
        </span>
        <br />
        <span>
          <b>Contractual hours (week): </b>
          {row['contractualHours'] ? row['contractualHours'] : 0}
        </span>
      </div>
    ),
  };

  return (
    <>
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={team}
        columns={columns}
        wrapperClasses="table-responsive"
        headerClasses="thead-light"
        expandRow={expandRow}
      // caption={}
      // caption="*Click on a row for more information"
      // pagination={paginationFactory({ sizePerPage: 10 })}
      />
      <DeleteTeamModal
        isShowing={isShowingDeleteModal}
        hide={toggleDeleteModal}
        reload={reload}
        data={selected}
      />
    </>
  );
}

export default TeamTable;
