/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  getCompany,
  getTeam,
} from '../../api/get';

import { useAuth } from '../../contexts/AuthContext';
import { getToday } from '../../utils/moment-utils';

import WeekButtons from '../WeekButtons';
import BranchSelector from '../BranchSelector';
import Content from '../Content';
import TitleBar from '../TitleBar';

import Loader from '../Loader';

import Shifts from './Shifts';

const ShiftsPage = () => {
  const { currentUserToken } = useAuth();

  const [selectedDate, setSelectedDate] = useState(getToday());
  const [branch, setBranch] = useState();

  const { data: company, error: errorCompany, mutate: mutateCompany } = getCompany(currentUserToken);
  const { data: team, error: errorteam, mutate: mutateTeam } = getTeam(currentUserToken);

  useEffect(() => {
    if (!errorCompany && company && company['branches'].length) {
      setBranch(company['branches'][0]);
      console.log('branch set again');
    }
  }, [company]);

  if (!company || errorCompany || !team || errorteam) {
    return <Loader />;
  }

  return (
    <Content>
      <TitleBar title="Shifts" status="None">
        <BranchSelector
          branch={branch}
          setBranch={setBranch}
          company={company}
        />
        <WeekButtons
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate} />
      </TitleBar>

      <hr />

      <Shifts
        company={company}
        mutateCompany={mutateCompany}
        team={team}
        selectedDate={selectedDate}
        branch={branch}
      />

    </Content>
  );
};

export default ShiftsPage;
