/* eslint-disable */
import React from 'react';

import {
  getScheduleInspection,
} from '../../api/get';

import { useAuth } from '../../contexts/AuthContext';

import { Button, Dropdown, ButtonGroup, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowsAltH } from '@fortawesome/free-solid-svg-icons';

import MenuDropdown from '../MenuDropdown';

import ScheduleTable from './ScheduleTable';
import ScheduleTableEmployeeView from './ScheduleTableEmployeeView';
import PublishModal from './PublishModal';

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

import useModal from '../../hooks/useModal';

import { LoadingCard } from '../Loader';

const Inspection = (props) => {
  const {
    team,
    selectedDate,
    branch,
    reload,
    isShiftView,
    setIsShiftView,
    processing,
  } = props;

  const { currentUserToken } = useAuth();
  const { data: schedule, error: errorSchedule, mutate: mutateSchedule } = getScheduleInspection(
    currentUserToken, getIsoWeek(selectedDate), getIsoYear(selectedDate), isShiftView ? branch : null);
  const { isShowing: isShowingPublishModal, toggle: togglePublishModal } = useModal();

  if (!schedule || errorSchedule) {
    return <LoadingCard title="MY SCHEDULE" />;
  }

  const reloadSchedule = () => {
    mutateSchedule();
  };

  return (
    <>
      <Card border="grey" className="border-3 shadow-sm mb-4">
        <Card.Header className="d-flex justify-content-between">
          <b>MY SCHEDULE</b>

          <div className="d-flex flex-row">
            <MenuDropdown>
              <Dropdown.Item className="fw-bold" onClick={togglePublishModal}>
                <FontAwesomeIcon icon={faEye} className="text-success me-2" />
                Publish schedule
              </Dropdown.Item>

              <Dropdown.Item className="fw-bold" onClick={() => console.log('click')}>
                <FontAwesomeIcon icon={faArrowsAltH} className="me-2" />
                Extend schedule
              </Dropdown.Item>
            </MenuDropdown>

            <ButtonGroup className="mx-1">
              <Button variant={isShiftView ? 'primary' : 'outline-primary'} size="sm" onClick={() => setIsShiftView(true)}>Shift view</Button>
              <Button variant={isShiftView ? 'outline-primary' : 'primary'} size="sm" onClick={() => setIsShiftView(false)}>Employee View</Button>
            </ButtonGroup>
          </div>

        </Card.Header>
        <Card.Body className="pb-0">
          {isShiftView ?
            <ScheduleTable
              schedule={schedule.schedule}
              team={team}
              reloadSchedule={reloadSchedule}
            />
            :
            <ScheduleTableEmployeeView
              schedule={schedule.employeeView}
            />
          }
        </Card.Body>
      </Card>

      <PublishModal
        isShowing={isShowingPublishModal}
        hide={togglePublishModal}
        reload={reload}
        selectedDate={selectedDate}
        processing={processing}
      />
    </>
  )
}

export default Inspection
