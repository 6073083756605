import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from '@themesberg/react-bootstrap';

import {
  parseTime,
} from '../../utils/helper';

const ScheduleTableEmployeeView = (props) => {
  const { schedule } = props;

  const splitShifts = (cellContent) => (
    cellContent.map((x) => (
      <>
        <Button
          key={x.id}
          variant="success"
          className="m-1"
          size="sm"
          onClick={() => console.log(x)}
        >
          {parseTime(x.startLocal, x.endLocal, x.endsNextDay)}
        </Button>
        <br />
      </>
    ))
  );

  const columns = [
    {
      dataField: 'data.name',
      text: 'Name',
      sort: false,
    },
    {
      dataField: 'monday',
      text: 'Monday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'tuesday',
      text: 'Tuesday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'wednesday',
      text: 'Wednesday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'thursday',
      text: 'Thursday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'friday',
      text: 'Friday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'saturday',
      text: 'Saturday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
    {
      dataField: 'sunday',
      text: 'Sunday',
      headerAlign: 'center',
      align: 'center',
      formatter: splitShifts,
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      keyField="data.email"
      data={schedule}
      columns={columns}
      headerClasses="thead-light"
      wrapperClasses="table-responsive"
      caption="Shifts per employee"
    // pagination={paginationFactory({ sizePerPage: 10 })}
    // expandRow={expandRow}
    />
  );
};

export default ScheduleTableEmployeeView;
