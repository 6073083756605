const apiUrl = (ext) => {
  if (process.env.REACT_APP_LOCAL) {
    return `http://127.0.0.1:5000/api/${ext}`;
  }
  if (process.env.REACT_APP_ENV === 'dev') {
    return `https://dev-dot-react-api-dot-rocketshyft-europe.ey.r.appspot.com/api/${ext}`;
  }
  // Production
  return `https://api.rocketshyft.com/api/${ext}`;
};

export default apiUrl;
