/* eslint-disable */
import React from 'react';
import { Button, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const BranchSelector = (props) => {
  const { branch, setBranch, company } = props;

  if (!branch && !company) {
    return null;
  }

  return (
    <Dropdown className="mx-1 d-inline-block">
      <Dropdown.Toggle as={Button} size="sm" variant="tertiary">
        <span>{branch}</span>
        <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-dark">
        {company['branches'].map((item, i) =>
          <Dropdown.Item
            key={i}
            disabled={branch === item ? true : false}
            className="fw-bold"
            href="#action"
            onClick={() => setBranch(item)}>
              <span>{item}</span>
            </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default BranchSelector;