import React from 'react';

const TitleBar = (props) => {
  const { title, status, children } = props;

  return (
    <div className="row justify-content-between">
      <div className="col-lg d-inline-flex">
        <h4>{title}</h4>
        {status && <small className="font-weight-bold ms-1">{status}</small>}
      </div>
      <div className="col-lg">
        <div className="float-end d-inline-flex">
          {children}
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
