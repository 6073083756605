/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Components
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import Preloader from './Preloader';

const RouteWithSidebar = ({ component: Component }) => {
  // eslint-disable-next-line no-unused-vars
  const { currentUser, currentUserToken } = useAuth();
  const [loaded, setLoaded] = useState(false);
  // const [userIsEmployee, setUserIsEmployee] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //     if (currentUserToken && currentUserToken.claims.type === 'employee' && loaded) {
  //         setUserIsEmployee(true);
  //     }
  // }, [currentUserToken, loaded]);

  if (currentUser == null) {
    return <Navigate to="/sign-in" />;
  }

  // if (userIsEmployee) {
  //   return (<Navigate to="/x" />);
  // };

  return (
    <>
      <Preloader show={!loaded} />
      <Sidebar />
      <main className="content">
        <Navbar />
        <Component />
        <Footer />
      </main>
    </>
  );
};

export default RouteWithSidebar;
