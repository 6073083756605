/* eslint-disable */
import React from 'react';
import { Card, Dropdown } from '@themesberg/react-bootstrap';

import {
  getAvailabilities,
} from '../../api/get';

import { useAuth } from '../../contexts/AuthContext';
import { LoadingCard } from '../Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faBell } from '@fortawesome/free-solid-svg-icons';
import AvailabilitiesTable from './AvailabilitiesTable';

import useModal from '../../hooks/useModal';

import MenuDropdown from '../MenuDropdown';

import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

import GenerateScheduleModal from './GenerateScheduleModal';
import SendReminderModal from './SendReminderModal';

const Availabilities = (props) => {
  const { selectedDate } = props;

  const { currentUserToken } = useAuth();

  const { data: availabilities, error: errorAvailabilities, mutate: mutateAvailabilities } = getAvailabilities(currentUserToken, getIsoWeek(selectedDate), getIsoYear(selectedDate));

  const reload = () => {
    mutateAvailabilities();
  }

  const { isShowing: isShowingGenerateModal, toggle: toggleGenerateModal } = useModal();
  const { isShowing: isShowingSendReminderModal, toggle: toggleSendReminderModal } = useModal();

  if (!availabilities || errorAvailabilities) {
    return <LoadingCard title="TEAM AVAILABILITIES" />;
  }

  return (
    <>
      <Card border="grey" className="border-3 shadow-sm mb-4">
        <Card.Header className='d-flex justify-content-between'>
          <b>TEAM AVAILABILITIES</b>
          <MenuDropdown>
            <Dropdown.Item className="fw-bold" onClick={toggleGenerateModal}>
              <FontAwesomeIcon icon={faRocket} className="me-2" /> Generate schedule
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold" onClick={toggleSendReminderModal}>
              <FontAwesomeIcon icon={faBell} className="text-danger me-2" /> Send reminder
            </Dropdown.Item>
          </MenuDropdown>
        </Card.Header>
        <Card.Body className="pb-0">
          <AvailabilitiesTable availabilities={availabilities} />
        </Card.Body>
      </Card>
      <GenerateScheduleModal
        isShowing={isShowingGenerateModal}
        hide={toggleGenerateModal}
        reload={reload}
        selectedDate={selectedDate}
      />

      <SendReminderModal
        isShowing={isShowingSendReminderModal}
        hide={toggleSendReminderModal}
        reload={reload}
        selectedDate={selectedDate}
      />
    </>
  )
};

export default Availabilities;
