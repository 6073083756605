import React from 'react';
import { Alert, Button } from '@themesberg/react-bootstrap';

const DismisssableAlert = (props) => {
  const {
    variant,
    show,
    toggle,
    message,
  } = props;

  return (
    <Alert
      variant={variant}
      show={show}
      onClose={toggle}
    >
      <div className="d-flex justify-content-between">
        <p>{message}</p>
        <Button variant="close" size="xs" onClick={toggle} />
      </div>
    </Alert>
  );
};

export default DismisssableAlert;
