import React from 'react';
import { Card } from '@themesberg/react-bootstrap';

import ScheduleStatusTable from './ScheduleStatusTable';
import { LoadingCard } from '../Loader';

const ScheduleStatus = (props) => {
  const { scheduleStatus, error } = props;

  if (!scheduleStatus || error) {
    return <LoadingCard title="SCHEDULE STATUS" />;
  }

  return (
    <Card border="grey" className="border-3 shadow-sm mb-4">
      <Card.Header className="d-flex justify-content-between">
        <b>SCHEDULE STATUS</b>
      </Card.Header>
      <Card.Body className="pb-0">
        <ScheduleStatusTable scheduleStatus={scheduleStatus} />
      </Card.Body>
    </Card>
  );
};

export default ScheduleStatus;
