import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import {
  parseTime,
  renderNoData,
} from '../../utils/helper';

const AvailabilitiesTable = (props) => {
  const { availabilities } = props;

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
    },
    {
      dataField: 'monday',
      text: 'Monday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'tuesday',
      text: 'Tuesday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'wednesday',
      text: 'Wednesday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'thursday',
      text: 'Thursday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'friday',
      text: 'Friday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'saturday',
      text: 'Saturday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
    {
      dataField: 'sunday',
      text: 'Sunday',
      formatter: (cellContent) => parseTime(
        cellContent.start,
        cellContent.end,
        cellContent.endsNextDay,
      ),
    },
  ];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <span>
          <b>Additional information:</b>
        </span>
        <br />
        <span>
          <b>Max shifts: </b>
          {row.maxShifts}
        </span>
        <br />
        <span>
          <b>Max hours:</b>
          {row.maxHrs}
        </span>
      </div>
    ),
  };

  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={availabilities.availabilities}
      columns={columns}
      wrapperClasses="table-responsive"
      headerClasses="thead-light"
      noDataIndication={renderNoData}
      expandRow={expandRow}
    // pagination={paginationFactory({ sizePerPage: 5 })}
    />
  );
};

export default AvailabilitiesTable;
