/* eslint-disable */
import React from 'react';
import {
  Card,
  Dropdown,
} from '@themesberg/react-bootstrap';

import {
  getShifts,
} from '../../api/get';

import ShiftsTable from './ShiftsTable';
import { useAuth } from '../../contexts/AuthContext';
import { LoadingCard } from '../Loader';
import MenuDropdown from '../MenuDropdown';

import AddShiftsModal from './AddShiftsModal';
import ClearShiftsModal from './ClearShiftsModal';
import TemplatesModal from './TemplatesModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import useModal from '../../hooks/useModal';
import { getIsoWeek, getIsoYear } from '../../utils/moment-utils';

const Shifts = (props) => {
  const { company, mutateCompany, team, selectedDate, branch } = props;

  const { currentUserToken } = useAuth();

  const { data: shifts, error: errorShifts, mutate: mutateShifts } = getShifts(currentUserToken, getIsoWeek(selectedDate), getIsoYear(selectedDate), branch);

  const { isShowing: isShowingAddModal, toggle: toggleAddModal } = useModal();
  const { isShowing: isShowingClearModal, toggle: toggleClearModal } = useModal();
  const { isShowing: isShowingTemplatesModal, toggle: toggleTemplatesModal } = useModal();

  if (!shifts || errorShifts) {
    return <LoadingCard title="MY SHIFTS" />;
  }

  const reload = () => {
    mutateShifts();
    mutateCompany();
  }

  return (
    <>
      <Card border="grey" className="border-3 shadow-sm mb-4">
        <Card.Header className='d-flex justify-content-between'>
          <b>MY SHIFTS</b>
          <MenuDropdown>
            <Dropdown.Item className="fw-bold" onClick={toggleAddModal}>
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Add shift
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" onClick={toggleTemplatesModal}>
              <FontAwesomeIcon icon={faFile} className="me-2" /> Templates
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold" onClick={toggleClearModal}>
              <FontAwesomeIcon icon={faTrashAlt} className="text-danger me-2" /> Clear shifts
            </Dropdown.Item>
          </MenuDropdown>
        </Card.Header>
        <Card.Body className="pb-0">
          <ShiftsTable
            shifts={shifts}
            reload={reload}
          />
        </Card.Body>
      </Card>

      <AddShiftsModal
        isShowing={isShowingAddModal}
        hide={toggleAddModal}
        reload={reload}
        selectedDate={selectedDate}
        branch={branch}
        company={company}
        team={team}
      />

      <ClearShiftsModal
        isShowing={isShowingClearModal}
        hide={toggleClearModal}
        reload={reload}
        branch={branch}
        selectedDate={selectedDate}
      />

      <TemplatesModal
        isShowing={isShowingTemplatesModal}
        hide={toggleTemplatesModal}
        reload={reload}
        branch={branch}
        selectedDate={selectedDate}
        company={company}
      />
    </>
  )
};

export default Shifts;
