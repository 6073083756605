/* eslint-disable */
import React from 'react';
import {
  getCompany,
} from '../../api/get';

import { useAuth } from '../../contexts/AuthContext';
import Content from '../Content';
import TitleBar from '../TitleBar';
import Team from './Team';
import Loader from '../Loader';

const TeamPage = () => {
  const { currentUserToken } = useAuth();

  const { data: company, error: errorCompany } = getCompany(currentUserToken);

  if (!company || errorCompany) {
    return <Loader />;
  }

  return (
    <Content>
      <TitleBar title="Team" status="Click on a row for more information" />

      <hr />

      <Team company={company} />

    </Content>
  );
};

export default TeamPage;
