import React from 'react';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import routes from '../../routes';
import BgImage from './assets/signin.svg';

const AccountTypePage = () => (
  <main>
    <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
      <Container>
        <p className="text-center">
          <Card.Link as={Link} to={routes.SigninPage.path} className="text-gray-700">
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" />
            {' '}
            Back to sign in
          </Card.Link>
        </p>
        <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
          <Col xs={12} className="d-flex align-items-center justify-content-center">
            <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h3 className="mb-0">Choose your account type</h3>
              </div>
              <Form className="mt-4">
                <Form.Group id="company" className="mb-4">
                  <Form.Label>I am a Company</Form.Label>
                  <Link to={routes.SignupCompanyPage.path}>
                    <Button variant="primary" className="w-100 my-2">
                      Create Company Account
                    </Button>
                  </Link>
                </Form.Group>
                <Form.Group id="employee" className="mb-4">
                  <Form.Label>I am an Employee</Form.Label>
                  <Link to={routes.SignupPage.path}>
                    <Button variant="primary" className="w-100 my-2">
                      Create Employee Account
                    </Button>
                  </Link>
                </Form.Group>
              </Form>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <span className="fw-normal">
                  Already have an account?
                  <Card.Link as={Link} to={routes.SigninPage.path} className="fw-bold">
                    {' Login here '}
                  </Card.Link>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </main>
);

export default AccountTypePage;
