import React from 'react';

import { Button, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const MenuDropdown = (props) => {
  const { children } = props;

  return (
    <Dropdown>
      <Dropdown.Toggle as={Button} size="sm" variant="primary">
        <span>Menu</span>
        <FontAwesomeIcon icon={faEllipsisV} className="ms-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-dark">
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuDropdown;
